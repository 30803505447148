import { Typography } from '@mui/material';
import Link from '@mui/material/Link';
import landingPageIcon from 'assets/landing-page-icon';
import { svgIcons } from 'assets/svg-icons';
import { BIZ_UPSELL_FEATURES } from './biz-analyst-banner.constants';
import {
  BizAnalystBannerWrapper,
  BizAnalystDetailsWrapper,
  BizUpSellSectionWrapper,
  TallyBannerContainer
} from './biz-analyst-banner.styled';
export const BizAnalystBanner = () => {
  const TallyBanner = () => (
    <TallyBannerContainer>
      <Typography variant="h5" className="detail">
        If you are using Tally * , check this out!
      </Typography>
    </TallyBannerContainer>
  );

  const BizUpSellSection = () => (
    <BizUpSellSectionWrapper>
      {BIZ_UPSELL_FEATURES.map(({ detail, icon }, index) => {
        const isLast = index === BIZ_UPSELL_FEATURES.length - 1;
        return (
          <div
            key={index}
            className="feature-container"
            style={{ marginBottom: isLast ? 0 : '0.8rem' }}
          >
            <div className="feature-icon">{icon}</div>
            <Typography variant="subtitle1" className="feature-detail">
              {detail}
            </Typography>
          </div>
        );
      })}
    </BizUpSellSectionWrapper>
  );

  const BizAnalystDetails = () => (
    <BizAnalystDetailsWrapper>
      <div className="biz-analyst-icon">{svgIcons.bizAnalystIcon}</div>
      <Typography variant="h2" className="title">
        <span className="number-one">The #1</span> Tally * on mobile app
      </Typography>
      <Typography variant="subtitle1" className="sub-title">
        Access your Tally * data and manage your business from your mobile
        anytime, anywhere.
      </Typography>
      <div className="app-download-container">
        <Link
          href="https://ba.sng.link/D71ns/i8tl"
          color="inherit"
          underline="none"
          target="_blank"
          rel="noreferrer"
          className="app-link"
        >
          <Typography>Learn More</Typography>
        </Link>
      </div>
    </BizAnalystDetailsWrapper>
  );
  return (
    <BizAnalystBannerWrapper>
      <div className="biz-detail-container">
        <BizAnalystDetails />
        <div className="bizBanner">
          <div className="bannerBg">{landingPageIcon.bgBanner}</div>
          <BizUpSellSection />
          <div className="bannerBg">{landingPageIcon.bgBanner}</div>
        </div>
      </div>
      <TallyBanner />
    </BizAnalystBannerWrapper>
  );
};
