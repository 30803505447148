import styled from '@emotion/styled';

export const FooterWrapper = styled('div')(({ theme }: any) => ({
  position: 'relative',
  '.footer': {
    marginTop: '7rem',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: `${theme.palette.commonBgColor.paleGrey}`,
    padding: '1rem 0 1rem 0',
    '&__content': {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '0rem 6.5rem',
      padding: '0.5rem',
      '&__title': {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '2rem',
        paddingRight: '1rem',
        '&__logo': {
          marginBottom: '1rem',
          svg: {}
        },
        '&__svg': {
          padding: '1rem 0 0 0;',
          svg: {
            paddingRight: '0.5rem'
          }
        },
        '&__iso': {
          position: 'relative',
          display: 'flex',
          alignItems: 'flex-start',
          '&__label': {
            fontSize: 14,
            fontWeight: 400,
            display: 'flex',
            alignItems: 'center',
            height: '40px'
          }
        }
      },
      '&__links': {
        display: 'flex',
        flexDirection: 'column',
        width: '33%',
        marginTop: '2rem',
        '.pointer': {
          cursor: 'pointer',
          padding: '0.5rem 0'
        }
      },
      '.contact-us-section': {
        width: '33%',
        marginTop: '2rem',
        '&__description': {
          padding: '0 0 1.5rem 0'
        },
        '&__icons': {
          display: 'flex',
          padding: '0.5rem 0',
          alignItems: 'center',
          '&__content': {
            padding: '0 0.5rem'
          }
        }
      },
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        margin: '1rem',
        '&__title': {
          width: '100%'
        },
        '&__links': {
          width: '100%'
        },
        '.contact-us-section': {
          width: '100%'
        }
      }
    },
    '.divider': {
      margin: '0 6.5rem'
    },
    '&__rights': {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '0 6.5rem',
      padding: '1rem',
      '&__reserved': {
        textAlign: 'center',
        padding: '1rem',
        color: theme.palette.textColors.grey,

        [theme.breakpoints.down('md')]: {
          padding: '0 1rem'
        }
      },
      '&__policy': {
        display: 'flex',
        '&__content': {
          padding: '1rem'
        }
      },
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column-reverse',
        margin: '0 2rem',
        '&__policy': {
          justifyContent: 'center'
        }
      }
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '0'
    }
  },
  '.tally-disclaimer': {
    padding: '1% 2% 1% 2%',
    border: '0.5px dashed #7D8693',
    borderRadius: '8px',
    background: '#FFFEF1',
    marginLeft: '6%',
    marginRight: '6%',
    fontSize: '14px',
    color: '#515E74'
  },
  '.demo-account-widget': {
    margin: `${theme.spacing(0)} ${theme.spacing(28)} ${theme.spacing(
      16
    )} ${theme.spacing(26)}`
  },
  [theme.breakpoints.down('md')]: {
    margin: '0'
  }
}));

export const FooterCard = styled('div')(({ theme }: any) => ({
  boxSizing: 'border-box',
  height: '27rem',
  top: '-13.5rem',
  width: 'calc(100% - 13.5rem)',
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: `${theme.palette.commonBgColor.lightPink}`,
  margin: '4rem 6.5rem 0rem 6.5rem',
  padding: '0 3rem',
  borderRadius: '1.5rem',
  '.header-box': {
    textAlign: 'initial',
    color: `${theme.palette.textColors.darkBlack}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '.header-title': {
      maxWidth: '35rem'
    },
    '.custom-feature-list-wrapper': {
      [theme.breakpoints.down('md')]: {
        flexDirection: 'row',
        alignItems: 'flex-start'
      }
    },
    '.custom-feature-detail': {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: theme.palette.textColors.blackishGreen,
      svg: {
        display: 'none',
        marginBottom: '1rem'
      },
      [theme.breakpoints.down('md')]: {
        svg: {
          display: 'block',
          path: {
            fill: theme.palette.commonBgColor.fluorescentGreen
          }
        }
      }
    },
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      alignItems: 'center'
    }
  },
  '.phone_mockup': {
    position: 'relative',
    height: '110%',
    bottom: '2.7rem',

    [theme.breakpoints.down('lg')]: {
      width: '45%'
    },

    [theme.breakpoints.down('md')]: {
      bottom: '0',
      marginTop: '1rem',
      width: '75%'
    }
  },
  '.phone-number-form': {
    display: 'flex',
    maxWidth: '30rem',
    [theme.breakpoints.down('xl')]: {
      p: {
        fontSize: '0.875rem'
      },
      input: {
        fontSize: '0.875rem'
      }
    },
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  '.startTrial_btn': {
    backgroundColor: `${theme.palette.error.main}`,
    fontSize: '18px',
    fontWeight: 600,
    ':hover': {
      backgroundColor: `${theme.palette.commonBgColor.darkRed}`
    }
  },
  '.scanner-image': {
    maxHeight: '208px',
    maxWidth: '208px'
  },
  '.scanner-section': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '1rem 0',
    color: `${theme.palette.textColors.blackishGreen}`,
    img: {
      width: '100%',
      padding: '0.5rem 0',
      [theme.breakpoints.down('lg')]: {
        width: '115%'
      }
    },
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  '.apps-section': {
    display: 'flex',
    justifyContent: 'space-between',
    color: `${theme.palette.commonBgColor.white}`,
    width: '21rem',
    img: {
      padding: '0.5rem 0',
      width: '10rem'
    },
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  '.appImageBadge': {
    alignSelf: 'center'
  },
  [theme.breakpoints.down('md')]: {
    position: 'static',
    flexDirection: 'column',
    height: 'auto',
    width: 'auto',
    borderRadius: 0,
    margin: '0',
    paddingTop: '1rem',
    alignItems: 'center'
  }
}));

export const FieldWrapper = styled('div')(({ theme }: any) => ({
  height: '100%',
  width: '8rem',
  display: 'flex',
  flex: '3',
  '.country-code': {
    width: '2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: `${theme.palette.commonBgColor.white}`,
    color: 'black',
    borderRadius: '0.25rem 0 0 0.25rem'
  },
  '.phone-number': {
    border: 'none',
    color: 'black',
    width: '96%',
    height: '96%',
    backgroundColor: `${theme.palette.commonBgColor.white}`,
    borderRadius: '0.5rem',
    div: {
      div: {
        padding: '0 6px'
      }
    },
    'input.MuiInputBase-inputSizeSmall': {
      fontSize: '18px'
    },
    [theme.breakpoints.down('xl')]: {
      height: '93%'
    }
  }
}));

export const FeatureCheckWrapper = styled('div')(({ theme }: any) => ({
  display: 'flex',
  justifyContent: 'space-between',
  '.feature-check': {
    display: 'flex',
    alignItems: 'center',
    svg: {
      marginRight: '0.5rem',
      path: {
        fill: '#32D583'
      }
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      svg: {
        marginBottom: '.25rem'
      }
    }
  }
}));
