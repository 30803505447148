import styled from '@emotion/styled';

export const FeatureTitle = styled('div')(({ theme }: any) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '2rem 0rem',
  '.title1': {
    color: `${theme.palette.commonBgColor.brightRed}`,
    marginBottom: '1rem',

    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
      backgroundColor: '#FAE0E4',
      padding: '0.4rem 1rem',
      borderRadius: '1rem'
    }
  },
  '.title2': {
    width: '60%',
    fontSize: '48px',
    lineHeight: '60px',
    letterSpacing: '-0.01rem',
    textAlign: 'center',
    fontWeight: '700'
  },
  [theme.breakpoints.down('md')]: {
    margin: '0rem 0rem 2rem 0rem',
    '.title1': {
      marginBottom: '0'
    },
    '.title2': {
      fontWeight: '700',
      fontSize: '1.75rem',
      padding: '0.4rem 1rem',
      borderRadius: '1rem',
      width: '100% !important',
      lineHeight: 'normal'
    }
  }
}));

export const FeatureCards = styled('div')(({ theme }: any) => ({
  margin: '0 6.5rem',
  marginTop: '5rem',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '2rem',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    margin: '0 1rem'
  }
}));

export const FeatureCard = styled('div')(({ theme, index }: any) => ({
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'space-between',
  // alignItems: index === 0 ? 'center' : 'stretch',
  flexDirection: 'column',
  width: '38%',
  overflow: 'hidden',
  borderRadius: '1rem',
  background: `${theme.palette.commonBgColor.grey}`,
  boxSizing: 'border-box',
  svg: {
    width: 'auto',
    marginRight: '2rem'
  },

  '.feature_details': {
    margin: index == 1 ? '0rem 0.5rem 0rem 0.5rem' : '0rem 0.5rem 2rem 0.5rem',
    maxWidth: '55rem',
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    },

    '&__header': {
      margin: '1.5rem 0rem 0rem 1.5rem',
      display: 'flex',
      alignItems: 'center',

      '&__title': {
        fontSize: '1.5rem'
      },
      svg: {
        marginRight: '0.5rem',
        marginTop: '0.2rem',
        alignSelf: index === 2 ? 'start' : 'end',
        width: 30,
        height: 30
      }
    },
    '&__description': {
      margin: '0rem 0rem 0rem 1.5rem',
      lineHeight: '1rem',
      padding: '1rem 1rem .5rem 0',
      color: `${theme.palette.textColors.grey}`
    },
    '.feature_list': {
      margin: '0rem 0rem 0rem 1.5rem',
      display: 'flex',
      alignItems: 'center',
      padding: '0.5rem 1rem 0rem 0rem',
      color: `${theme.palette.textColors.grey}`,
      '&_item': {
        paddingLeft: '0.5rem'
      },
      svg: {
        marginRight: '.5rem',
        width: 'auto',
        height: 'auto'
      }
    }
  },
  '.featureImage': {
    // width: 'auto',
    // marginRight: index == 0 ? '3rem' : '0rem',
    // alignSelf: index == 1 ? 'center' : index == 2 ? 'start' : 'end',
    svg: {
      width: '100%',
      height: '100%',
      marginRight: '0rem !important'
    }
  },

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    width: '100%'
  }
}));

export const GrowingBusinessWrapper = styled('div')(({ theme }: any) => ({
  [theme.breakpoints.down('md')]: {
    // display: 'none'
  },
  position: 'relative',
  '.left-asset': {
    position: 'absolute',
    top: '-5rem',
    left: '-3rem',
    zIndex: -1,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  '.right-asset': {
    position: 'absolute',
    top: '-5rem',
    right: '0rem',
    zIndex: -1,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
}));
