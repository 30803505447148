import { svgIcons } from 'assets/svg-icons';
import { isIOSDevice } from './helper';

export const PAGE_HEADER = {
  HEADER: {
    SECTION_1: 'Business hua',
    SECTION_2: '(aur bhi)',
    SECTION_3: 'easy'
  },
  DESCRIPTION: 'with Khatabook on Desktop',
  COUNTRYCODE: '+91'
};
export const FEATURE = {
  GST: 'GST Invoice',
  INVENTORY: 'Inventory Management',
  PAYMENT: 'Payment Reminders'
};

export const GROWING_BUSINESS = {
  HEADER: 'Powerful features to help you',
  SUB_HEADER: 'Built with features for growing businesses',
  MOBILE_HEADER: 'How you benefit'
};

export const FEATURE_OBJECT = [
  {
    // ICON: svgIcons.clock,
    IMAGE: svgIcons.automatedPaymentReminder,
    TITLE: 'Business management on the go',
    DESCRIPTION: 'Use Khatabook across desktop and mobile devices',
    LIST: [
      'Real time data sync across different devices',
      'Easy access for both staff and business owners'
    ],
    shouldShowInDesktop: true
  },
  {
    // ICON: svgIcons.gstIcon,
    IMAGE: svgIcons.gstBills,
    TITLE: 'GST & Non-GST Bills',
    DESCRIPTION: 'Create customised invoices & easily share with customers',
    LIST: [
      'Custom fields like Vehicle No, PO No etc.',
      'Detailed sales and purchase reports',
      'No double-entry across khata and cashbook'
    ],
    shouldShowInDesktop: false
  },
  {
    // ICON: svgIcons.inventoryManagement,
    IMAGE: svgIcons.business_loans,
    TITLE: 'One app for all your business needs',
    DESCRIPTION: 'Smart Features for your business',
    LIST: [
      'One place for all credit/debit transactions',
      'Get 3X faster recollection with Auto Reminders'
    ],
    shouldShowInDesktop: true
  },
  {
    // ICON: svgIcons.inventoryManagement,
    IMAGE: svgIcons.inventoryLanding,
    TITLE: 'Inventory Management',
    DESCRIPTION: 'Track your stock in/out and profits',
    LIST: [
      'Detailed stock history with sale & purchase price with notes',
      'Low stock tracking',
      'Profit tracking at daily, weekly and monthly level'
    ],
    shouldShowInDesktop: false
  }
];

export const TALLY_DISCLAIMER =
  '*Tally is the trademark of its respective owner and is not affiliated,\n' +
  'endorsed, connected or sponsored in any way to this website, mobile\n' +
  'application or any of our affiliate sites. The same is used in\n' +
  'accordance with honest practices and not used with any intention to\n' +
  "misguide customers to take unfair advantage of the trademark's\n" +
  "distinct character or harm the holder's reputation.";

export const BANNER_DATA_S3_URL =
  'https://assets.khatabook.com/banners/web-banners/default/configs/web_generic_banners.json';
export const PORTFOLIO_OBJECT = [
  {
    ICON: svgIcons.attendance,
    TITLE: 'Manage Multiple Businesses',
    DESCRIPTION: 'Manage multiple businesses from the same account'
  },
  {
    ICON: svgIcons.backup,
    TITLE: 'Automatic Backup',
    DESCRIPTION:
      'Your data is automatically backed up when connected to internet in a secured way.'
  },
  {
    ICON: svgIcons.businessReports,
    TITLE: 'GST & Business Reports',
    DESCRIPTION:
      'Get all GST reports & other insights to run your business efficiently.'
  }
];

export const BUSINESS_TYPES_HEADER = {
  HEADER: 'who should use?',
  SUB_HEADER: 'Boosting businessness of all types'
};
export const BUSINESS_TYPES = [
  {
    ICON: svgIcons.retailers,
    TITLE: 'Retailers',
    DESCRIPTION_LIST: [
      'Faster billing with POS mode',
      'Thermal printouts',
      'Inventory management & more'
    ]
  },
  {
    ICON: svgIcons.wholesalers,
    TITLE: 'Wholesalers & Distributors',
    DESCRIPTION_LIST: [
      'Easily track due amount from customers & send automated reminders',
      'Sharing of customised invoice on WhatsApp & SMS',
      'Inventory management & more'
    ]
  },
  {
    ICON: svgIcons.manufacturers,
    TITLE: 'Micro Manufacturers',
    DESCRIPTION_LIST: [
      'Track all sales & purchases in detail',
      'Easily track due amount from customers & send automated reminders',
      'Inventory management along with raw materials'
    ]
  }
];

export const BILLING_ACCOUNTING_FEATURES = [
  'Manage Digital Bahi Khata',
  'Send Payment Reminders for easy collection'
];
export const APP_DOWNLOAD_LINK_LIST_FOR_FOOTER = [
  {
    image: svgIcons.googlePlayBadge,
    link: isIOSDevice()
      ? 'https://kb.sng.link/Bjhws/ihwb/x68n'
      : 'https://play.google.com/store/apps/details?id=com.vaibhavkalpe.android.khatabook&hl=en_IN&gl=US'
  },
  {
    image: svgIcons.appStoreBadge,
    link: 'https://apps.apple.com/in/app/khata-book-udhar-bahi-khata/id1488204139'
  }
];

export const LandingPageServerImages = {
  TOP_RIGHT_PANE:
    'https://khatabook-assets.s3.ap-south-1.amazonaws.com/common/images/website_adv_banner.svg'
};
