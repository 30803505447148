import styled from '@emotion/styled';
import { padding } from '@mui/system';

export const BizAnalystBannerWrapper = styled('div')(({ theme }: any) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  margin: '4rem 6.5rem',
  borderRadius: '0.8rem',
  backgroundColor: theme.palette.commonBgColor.neonGreen,
  height: 400,
  border: `0.1rem solid ${theme.palette.commonBgColor.light2Blue}`,

  '.biz-detail-container': {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    justifyContent: 'space-between'
  },
  [theme.breakpoints.down('md')]: {
    margin: '1rem',
    display: 'block',
    height: 'fit-content',
    flexDirection: 'column',
    '.biz-detail-container': {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'space-between'
    },
    '.biz-analyst-icon': {
      display: 'flex',
      justifyContent: 'center'
    }
  },
  '.bizBanner': {
    display: 'flex',
    flexDirection: 'column'
  },
  '.bannerBg': {
    display: 'flex',
    alignSelf: 'end',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
}));

export const BizAnalystDetailsWrapper = styled('div')(({ theme }: any) => ({
  padding: '2rem 3rem',
  display: 'flex',
  flexDirection: 'column',
  flex: 0.8,
  '.title': {
    fontSize: '42px',
    marginTop: '28px',
    fontFamily: 'Poppins, cursive'
  },
  '.number-one': {
    color: theme.palette.commonBgColor.lightBlue
  },
  '.sub-title': {
    color: theme.palette.textColors.black,
    width: '75%',
    fontSize: '20px',
    marginTop: '0.8rem'
  },
  '.app-download-container': {
    marginTop: '1rem',
    padding: '.75rem 1rem',
    fontSize: '16px',
    height: '3rem',
    width: 'fit-content',
    backgroundColor: 'white',
    borderRadius: '12px'
  },
  '.app-link': {
    marginRight: '1.5rem'
  },
  [theme.breakpoints.down('md')]: {
    padding: '2rem',
    alignItems: 'center',
    '.title': {
      textAlign: 'center',
      fontSize: '1.25rem'
    },
    '.sub-title': {
      fontSize: '0.875rem',
      textAlign: 'center'
    }
  }
}));

export const BizUpSellSectionWrapper = styled('div')(({ theme }: any) => ({
  backgroundColor: theme.palette.commonBgColor.paleBlue,
  flex: 0.4,
  padding: '1.75rem 2rem',
  alignSelf: 'center',
  borderBottomLeftRadius: '20px',
  borderTopLeftRadius: '20px',
  alignItems: 'end',
  '.feature-container': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  '.feature-detail': {
    marginLeft: '2rem',
    color: 'white',
    fontSize: '18px'
  },
  [theme.breakpoints.down('md')]: {
    width: '67%',
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    '.feature-container': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      svg: {
        height: 16,
        width: 16
      }
    },
    '.feature-detail': {
      fontSize: '0.75rem',
      marginLeft: '1rem'
    }
  }
}));

export const TallyBannerContainer = styled('div')(({ theme }: any) => ({
  position: 'absolute',
  left: '2.6rem',
  top: '-0.9rem',
  background: theme.palette.yellow[600],
  padding: '0.2rem 1.6rem',
  borderRadius: '0.4rem',
  '.detail': {
    fontSize: '0.9rem'
  },
  [theme.breakpoints.down('md')]: {
    left: '1rem'
  }
}));
