import React from 'react';
import { HowWeHelpWrapper } from './styled';
import Typography from '@mui/material/Typography';
import Image from 'features/core/Image';
import Button from '@mui/material/Button';

const HowWeHelp = () => {
  return (
    <HowWeHelpWrapper>
      <Typography
        className="title1"
        variant="h6"
        sx={{
          color: '#DC343B',
          fontWeight: '700',
          backgroundColor: '#FAE0E4',
          padding: '0 1rem',
          borderRadius: '1rem',
          width: 'fit-content',
          margin: '0 0 1rem 0'
        }}
      >
        How we help
      </Typography>

      <Typography
        variant="h6"
        sx={{
          fontWeight: '700',
          fontSize: '1.5rem',
          textAlign: 'center',
          padding: '0 2rem',
          textTransform: 'lowercase'
        }}
      >
        One platform for all your business needs
      </Typography>

      <Typography className="caption" variant="body2">
        Access your account anywhere, anytime
      </Typography>

      <div className="mobileGridContainer">
        <img
          className="img"
          src="https://khatabook-assets.s3.ap-south-1.amazonaws.com/common/images/mobile_grid_1.svg"
          alt="mobile-grid-1"
        />
        <Typography variant="body2" className="title1">
          Digital India ka Digital Khata
        </Typography>
        <Typography variant="body2" className="caption">
          Digital Ledger, Invoice Creation, Inventory Management, Loans and more
        </Typography>
        <Button
          variant="contained"
          className="exloreKB"
          aria-label="VIEW ALL"
          onClick={() => console.log('clicked')}
        >
          Explore Khatabook
        </Button>
      </div>
    </HowWeHelpWrapper>
  );
};

export default HowWeHelp;
