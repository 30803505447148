import DLGDisclosure from 'components/DLGDisclosure';

export const PAGE_FOOTER = () => ({
  HEADER: 'Get started with Khatabook.',
  COUNTRYCODE: '+91',
  SCAN: 'Scan to download the app',
  CONTENT: 'Khatabook is India’s largest business management platform.',
  COMMENT: "We'd love to hear from you!",
  LINK_HEADER: 'Company',
  LINK_HEADER_LEGAL: 'Legal',
  DESCRIPTION: 'You can reach out to us at:',
  PHONE_NUMBER: '+91-9606800800',
  EMAIL: ' feedback@khatabook.com',
  ADDRESS:
    '1539, 18th Cross Road, Sector 3, HSR Layout, Bengaluru, Karnataka, India, 560102',
  RIGHTS: '© 2024 ADJ Utility Apps Private Limited.',
  ALL_RIGHTS_RESERVED: 'All rights reserved',
  TERMS_CONDITION: 'Terms & Conditions',
  PRIVACY: 'Privacy Policy',
  GRIEVANCES_REDRESSAL: 'Grievances & Redressal',
  LENDING_TERMS: 'Lending T&C',
  DLG_DISCLOSURE: 'DLG Disclosure'
});

export const DEMO_ACCOUNT = () => ({
  TITLE: ' No signup required.',
  CTA_TITLE: 'View Demo Account',
  DESCRIPTION: 'Try Khatabook Desktop on a sample DEMO account .'
});

export const FOOTER_LINKS = [
  { NAME: 'About Us', LINK: 'https://khatabook.com/about/' },
  { NAME: 'Careers', LINK: 'https://khatabook.com/hiring/' },
  { NAME: 'Blog', LINK: 'https://khatabook.com/blog/' },
  { NAME: 'Newsroom', LINK: 'https://newsroom.khatabook.com/' },
  { NAME: 'Lending Partner', LINK: ' https://khatabook.com/lending-partner' }
  // {
  //   NAME: 'Grievances and Redressal',
  //   LINK: 'https://khatabook.com/grievance-redressal-policy'
  // }
];

export const FOOTER_LINKS_LEGAL = [
  { NAME: 'Terms & Conditions', LINK: 'https://khatabook.com/terms' },
  { NAME: 'Privacy Policy', LINK: 'https://khatabook.com/privacy' },
  { NAME: 'Lending T&C', LINK: 'https://khatabook.com/lending-terms' },
  {
    NAME: 'Grievance & Redressal',
    LINK: 'https://khatabook.com/grievance-redressal-policy'
  },
  {
    NAME: 'DLG Disclosure',
    LINK: `${process.env.NEXT_PUBLIC_BASE_URL}/dlg-disclosure`
  }
];
